import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  solutions: [],
  questions: [],
  chatUser: null,
  currentQuestion: {},
  userAnswer: "",
  correctAnswer: "",
};

export const chatSlice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setCurrentQuestionRedux: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setUserAnswerRedux: (state, action) => {
      state.userAnswer = action.payload;
    },
    setCorrectAnswerRedux: (state, action) => {
      state.correctAnswer = action.payload;
    },
    addQuestion: (state, action) => {
      const question = action.payload;
      state.questions = [...state.questions, ...question];
    },
    addSolutions: (state, action) => {
      const solution = action.payload;
      state.solutions = [...state.solutions, ...solution];
    },
    setChatUser: (state, action) => {
      state.chatUser = action.payload.chatUser;
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
    clearSolutions: (state) => {
      state.solutions = [];
    },
    // Add more reducer functions as needed
  },
});

export const { setCurrentQuestionRedux, addSolutions, clearSolutions, addQuestion, setChatUser, clearQuestions } = chatSlice.actions;
export const selectQuestions = (state) => state.chat.questions;
export const selectSolutions = (state) => state.chat.solutions;
export const selectChatUser = (state) => state.chat.chatUser;
export const selectCurrentQuestion = (state) => state.chat.currentQuestion;

export const selectCorrectAnswer = (state) => state.chat.currentQuestion.correct_answer;
export const selectUserAnswer = (state) => state.chat.currentQuestion.user_answer;


export default chatSlice.reducer;
